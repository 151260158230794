/*
 * @Description: 常量定义
 * @version: v1.0.0
 * @Author: ladd
 * @Date: 2021-08-28 10:45:02
 * @LastEditors: ladd
 * @LastEditTime: 2021-09-06 15:41:46
 */
export const nameMap = {
  cardType: {
    1: '储蓄卡',
    2: '信用卡'
  },
  displayLogo: {
    1: '显示',
    99: '不显示'
  },
  channelType: {
    1: '银行渠道',
    2: '员工渠道',
    3: '商户渠道'
  }
}
export const bankTypeArr = [{
  bank_type: 1,
  name: '总行'
}, {
  bank_type: 2,
  name: '省分行'
}, {
  bank_type: 3,
  name: '市分行'
}, {
  bank_type: 4,
  name: '支行'
}]

export const cardTypeArr = [{
  card_type: 1,
  name: '储蓄卡',
  enum_str: '_DEBIT',
}, {
  card_type: 2,
  name: '信用卡',
  enum_str: '_CREDIT'
}]

export const channelTypeArr = [{
  id: 1,
  name: '银行渠道'
}, {
  id: 2,
  name: '员工渠道'
}, {
  id: 3,
  name: '商户渠道'
}]

export const displayLogoArr = [{
  id: 1,
  name: '显示'
}, {
  id: 99,
  name: '不显示'
}]